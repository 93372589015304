<template>
  <div>
    <b-table-simple
      hover
      small
      caption-top
      responsive
      class="table-simple"
    >
      <colgroup><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col></colgroup>
      <b-thead>
        <b-tr>
          <b-th
            colspan="1"
            rowspan="4"
          >
            TÊN CHỈ TIÊU THỐNG KÊ
          </b-th>
          <b-th
            class
            colspan="11"
          >
            PHÂN LOẠI TNLĐ THEO MỨC ĐỘ THƯƠNG TẬT
          </b-th>
          <b-th
            class
            colspan="6"
          >
            THIỆT HẠI DO TNLĐ
          </b-th>
        </b-tr>

        <b-tr>
          <b-th
            class
            colspan="3"
          >
            SỐ VỤ (VỤ)
          </b-th>
          <b-th
            class
            colspan="8"
          >
            SỐ NGƯỜI BỊ NẠN (NGƯỜI)
          </b-th>
          <b-th
            colspan="1"
            rowspan="3  "
          >
            TỔNG SỐ NGÀY NGHỈ VÌ TAI NẠN LAO ĐỘNG
          </b-th>
          <b-th
            class
            colspan="4"
          >
            CHI PHÍ TÍNH BẰNG TIỀN (1.000Đ)
          </b-th>
          <b-th
            colspan="1"
            rowspan="3"
          >
            THIỆT HẠI TÀI SẢN (1.000Đ)
          </b-th>
        </b-tr>

        <b-tr>

          <b-th
            colspan="1"
            rowspan="2"
          >
            TỔNG SỐ
          </b-th>
          <b-th
            colspan="1"
            rowspan="2"
          >
            SỐ VỤ CÓ NGƯỜI CHẾT
          </b-th>
          <b-th
            colspan="1"
            rowspan="2"
          >
            SỐ VỤ CÓ 2 NGƯỜI BỊ NẠN TRỞ LÊN
          </b-th>
          <b-th
            class
            colspan="2"
          >
            TỔNG SỐ
          </b-th>
          <b-th
            class
            colspan="2"
          >
            SỐ LAO ĐỘNG NỮ
          </b-th>
          <b-th
            class
            colspan="2"
          >
            SỐ NGƯỜI CHẾT
          </b-th>
          <b-th
            class
            colspan="2"
          >
            SỐ NGƯỜI BỊ THƯƠNG NẶNG
          </b-th>

          <b-th
            colspan="1"
            rowspan="2"
          >
            TỔNG SỐ
          </b-th>
          <b-th
            class
            colspan="3"
          >
            KHOẢN CHI CỤ THỂ CỦA CƠ SỞ
          </b-th>

        </b-tr>

        <b-tr>

          <b-th
            class
            colspan="1"
          >
            TỔNG SỐ
          </b-th>
          <b-th
            class
            colspan="1"
          >
            NẠN NHÂN KHÔNG THUỘC QUYỀN QUẢN LÝ
          </b-th>
          <b-th
            class
            colspan="1"
          >
            TỔNG SỐ
          </b-th>
          <b-th
            class
            colspan="1"
          >
            NẠN NHÂN KHÔNG THUỘC QUYỀN QUẢN LÝ
          </b-th>
          <b-th
            class
            colspan="1"
          >
            TỔNG SỐ
          </b-th>
          <b-th
            class
            colspan="1"
          >
            NẠN NHÂN KHÔNG THUỘC QUYỀN QUẢN LÝ
          </b-th>
          <b-th
            class
            colspan="1"
          >
            TỔNG SỐ
          </b-th>
          <b-th
            class
            colspan="1"
          >
            NẠN NHÂN KHÔNG THUỘC QUYỀN QUẢN LÝ
          </b-th>

          <b-th
            class
            colspan="1"
          >
            Y TẾ
          </b-th>
          <b-th
            class
            colspan="1"
          >
            TRẢ LƯƠNG TRONG THỜI GIAN/ĐIỀU TRỊ
          </b-th>
          <b-th
            class
            colspan="1"
          >
            BỒI THƯỜNG/ TRỢ CẤP
          </b-th>

        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(item, index) in dataList"
          :key="index"
        >
          <b-td class="pb-1 pt-1">
            {{ item.name }}
          </b-td>
          <b-td
            v-for="key in listAccidentKey"
            :key="key"
            class="pb-1 pt-1"
          >
            <b-form-input
              :id="`focus-inputed-${key}-${index}`"
              :value="item[key]"
              :disabled="key === 'totalCost'"
              @focus="focusValueChange(key, index)"
              @input="onChangeTotalCost(key, $event, item)"
              @change="onChangeAmountAccident($event, key, index)"
            />
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <div v-if="dataList.length === 0">
      <div class="empty-table d-block">
        <div class="w-100 d-flex align-items-center justify-content-center">
          <b-img
            class="img-empty-table"
            style="width: 400px"
            fluid
            :src="$emptyImg"
            alt="empty table"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BImg,
  BThead,
  BFormInput,
} from 'bootstrap-vue'

import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../../constants/ConstantsApi'

export default {
  components: {
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BThead,
    BImg,
    BFormInput,
  },

  props: {
    submitReportId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {

      dataList: [],
      urlQuery: {
        submitReportId: '',
        tab: 3,
      },
      listAccidentKey: [
        'totalCase',
        'totalCaseDeath',
        'totalCase2Injury',
        'totalWorker',
        'totalWorkerNotOwner',
        'totalFemaleWorker',
        'totalFemaleWorkerNotOwner',
        'countDeath',
        'countDeathNotOwner',
        'countSerious',
        'countSeriousNotOwner',
        'absent',
        'totalCost',
        'healthCost',
        'salaryCost',
        'indemnifyCost',
        'assetCost',
      ],
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    // Fetch data
    async fetchData(urlQuery) {
      this.urlQuery.submitReportId = this.submitReportId
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_SUBMIT_REPORT_ACCIDENT_TITLES, {
        params: this.urlQuery,
      })
      this.dataList = data
      this.$hideLoading()
    },

    // Gọi API khi điền vào input
    async onChangeAmountAccident(val, key, index) {
      const typeVal = val !== null && val !== '' ? Number(val) : null
      this.dataList[index][key] = Number(val)
      this.dataList[index].submitReportId = this.submitReportId
      if (!typeVal === true && typeVal !== 0) {
        this.dataList[index][key] = 0
        const el = document.getElementById(`focus-inputed-${key}-${index}`)
        el.style.border = '1px solid red'
        this.$root.$bvToast.toast('Bắt buộc điền số vào ô trống', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      } else {
        const el = document.getElementById(`focus-inputed-${key}-${index}`)
        el.style.border = '1px solid #A6A8B0'
        await axiosApiInstance.post(ConstantsApi.CREATE_SUBMIT_REPORT_ACCIDENT_TITLES, this.dataList[index])
      }
    },

    // Bôi đen giá trị sau khi click vào ô input
    focusValueChange(key, index) {
      const el = document.getElementById(`focus-inputed-${key}-${index}`)
      el.focus()
      el.setSelectionRange(0, el.value.length)
    },

    // Tổng chi phí thiệt hại
    onChangeTotalCost(key, val, obj) {
      const keys = ['healthCost', 'salaryCost', 'indemnifyCost']
      let total = 0
      if (keys.includes(key)) {
        keys.forEach(e => {
          if (key !== e) {
            total += Number(obj[e])
          } else {
            total += Number(val)
          }
        })
        obj.totalCost = total
      }
    },
  },
}
</script>

<style lang="scss">
  // .scroll-table {
  //   overflow-y: scroll;
  //   height: 365px;
  // }
  .table-simple {
    .table {
      thead {
        tr {
          th {
            background-color: rgba(34, 127, 244, 0.12) !important;
          }
        }
      }
    }
    .style-table-thread {
      width: 50px;
    }
    .bold-text {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #2E3A4A !important;
    }
    .italic-text {
      font-family: 'Montserrat';
      font-style: italic;
      font-size: 14px;
      color: #2E3A4A !important;
    }
  }

</style>
